// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getclientManagementList } from "./clientManagement.thunk";

const initialState = {
  clientManagentList: { isFetching: false, data: [], error: null },
};
const clientManagementSlice = createSlice({
  name: "clientManagement",
  initialState,

  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getclientManagementList.pending, (state) => {
      state.clientManagentList.data = [];
      state.clientManagentList.isFetching = true;
    });

    builder.addCase(getclientManagementList.fulfilled, (state, action) => {
      state.clientManagentList.data = action?.payload;
      state.clientManagentList.isFetching = false;
      state.clientManagentList.error = null;
    });

    builder.addCase(getclientManagementList.rejected, (state, action) => {
      state.clientManagentList.isFetching = false;
      state.clientManagentList.error = action?.payload?.message;
    });
  },
});

export default clientManagementSlice.reducer;
