import authHttp from "./authHttp";

export const getclientManagementListApi = async (payload) => {
  const url = `api/all-users/${payload.type}?page=${payload.page}`;
  const {
    data: { data },
  } = await authHttp.get(url);
  return data;
};

export const createCaret = async (payload) => {
  const url = "api/exchange-caret";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getUserDetails = async (payload) => {
  const url = `api/users/${payload.id}`;
  const { data } = await authHttp.get(url);
  return data?.data;
};

export const cancelSubscription = async (payload) => {
  const url = `api/subscriptions/cancel/${payload.id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addUserNotes = async (payload) => {
  const url = `api/add-notes/users`;
  const { data } = await authHttp.post(url, payload);
  return data;
};
