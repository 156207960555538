import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser } from "./currentUser.thunk";

const initialState = {
  isLoading: false,
  currentuserdata: [],
  error: null,
};

const currentUserSlice = createSlice({
  name: "currentuser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentuserdata = action.payload;
      state.error = null;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.currentuserdata = [];
    });
  },
});

export default currentUserSlice.reducer;
