const AUTH_ROUTES = {
  // USER
  APP_LOGIN: "/login",
  APP_REGISTER: "/register",

  // ADMIN
  ADMIN_LOGIN: "/admin/login",

  // USER AND ADMIN BOTH CAN USE
  RESET_PASSWORD: "/reset-password",

  // Calculator

  CALCULATOR: "/calculator",
};

const USER_ROUTES = {
  // HOME
  APP_HOME: "/apps/home",

  // MY PROJECTS
  MY_PROJECTS: "/apps/my-projects",
  CREATE_PROJECT: "/apps/my-projects/create-project",

  // USER PROFILE
  USER_PROFILE: "/apps/user-profile/",

  // STYLE PROFILE
  STYLE_PROFILE: "/apps/style-profile",
  STYLE_PROFILE_EDIT: "/apps/style-profile/edit",

  // SUBSCRIPTION
  SUBSCRIPTION: "/apps/subscriptions",

  // CARATS STATEMENT
  CARATS_STATEMENT: "/apps/carat-statement",

  // PRICING
  PRICING: "/apps/pricing",

  // Additional Edit

  ADDITIONAL_EDIT: "/apps/additional-edit",
};

const ADMIN_ROUTES = {
  // CLIENT MANAGEMENT
  APP_LOGIN: "/admin/login",
  CLIENT_MANAGEMENT: "/admin/client-management",
  CLIENT_DETAIL: "/admin/client-management/client-detail",

  // DISCOUNT COUPON
  DISCOUNT_COUPON: "/admin/discount-coupon",

  // PROJECT MANAGEMENT
  PROJECT_MANAGEMENT: "/admin/project-management",
  PROJECT_INFO: "/admin/project-info",
  STYLE_PROFILE_VIEW: "/admin/style-profile/view",
  POPUP_MANAGEMENT_PROJECT: "/admin/popup-management/new-project",
  POPUP_MANAGEMENT_ORDER: "/admin/popup-management/order-page",
  POPUP_MANAGEMENT_SUBSCRIPTION: "/admin/popup-management/subscription",
  EDITORS_MASTER: "/admin/editors-master",
  ARCHIVED_PROJECTS: "/admin/archived-projects",
};

export { AUTH_ROUTES, USER_ROUTES, ADMIN_ROUTES };
