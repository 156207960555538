// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
// import users from "./user";
import user from "./userSlice/user";
import auth from "./authentication";
import { combineReducers } from "redux";
import styleProfile from "./styleProfile";
import clientManagement from "./clientManagent/clientManagement";
import caretsStatement from "./caretsStatement/caratsStatement";
import subscription from "./subscription";
import currentUser from "./currentUserSlice/currentUser";
import projectManagement from "./projectManagement/projectManagement";
import discountCoupon from "./discountCoupon/discountCoupon";
import editorsMaster from "./editorsMaster/editorsMaster";
import archivedProjects from "./archivedProjects/archivedProjects";

const appReducers = combineReducers({
  auth,
  navbar,
  layout,
  styleProfile,
  user,
  clientManagement,
  caretsStatement,
  subscription,
  currentUser,
  projectManagement,
  discountCoupon,
  editorsMaster,
  archivedProjects,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = undefined; // Reset the state to undefined
  }
  return appReducers(state, action);
};

export default rootReducer;
