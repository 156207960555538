import authHttp from "./authHttp";

export const getProjectList = async (payload) => {
  const { page, ...rest } = payload;
  const url = `/api/list-project?page=${page}`;
  const { data } = await authHttp.post(url, { ...rest });
  return data;
};

export const getProjectInfo = async (id) => {
  const url = `/api/fetch-project/retrive/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const ChangeStatus = async (payload) => {
  const url = `/api/status-change?sub_project_id=${payload.id}`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getFeedbacks = async (payload) => {
  const url = `/api/feedback/get?create_project_id=${payload.create_project_id}&sub_project_id=${payload.sub_project_id}`;
  const { data } = await authHttp.get(url);
  return data.data;
};

export const createFeedback = async (payload) => {
  const url = `/api/feedback/send`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const etaEstimationDate = async (payload) => {
  const url = `api/add-estimated-time`;
  const { data } = await authHttp.post(url, payload);
  return data;
};
