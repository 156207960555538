/* eslint-disable semi */
import React from "react";
import Avatar from "@components/avatar";
import { X } from "react-feather";
import toast from "react-hot-toast";
import { Card } from "reactstrap";

const ToastContent = (props) => {
  const { message, t, icon, color } = props;
  return (
    <Card className="d-flex justify-content-between alifn-items-center m-0">
      <div className="d-flex ">
        <div>
          <Avatar size="sm" color={color ? color : "success"} icon={icon} />
        </div>
        <div className="d-flex">
          <span
            className="mx-1 fw-bolder text-capitalize text-break"
            color="#2d2d2d"
          >
            {message}
          </span>
          <div>
            <X
              size={18}
              className="cursor-pointer me-0 float-right"
              onClick={() => toast.dismiss(t)}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ToastContent;
